import React, { useEffect } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import Navigation from "../../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import RecoveryPeriodYou from "../../../../components/ContentRow/Step2/RecoveryPeriod/RecoveryPeriodYou";
import RecoveryPeriodSpouse from "../../../../components/ContentRow/Step2/RecoveryPeriod/RecoveryPeriodSpouse";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import './RecoveryPeriod.scss'
import { secondStepCompleted } from "../../../../store/actions/investorProfileActions";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const RecoveryPeriod = ( props ) => {
    const titles = {
        layoutTitle: 'YOUR INVESTOR PROFILE',
        sectionTitle: 'If your investment decreases due to market conditions, what is your anticipated recovery period?',
    }

    const title = "Retirement planning and recovery period for loss"

    useEffect(() => {
        props.secondStepCompleted(false)
    }, [props])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep2={(3/9)*100}
            active={'step2'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='recoveryPeriod'
                sectionTitle={titles.sectionTitle}
            >

                {
                    (props.longTermReturnsYou === 'Yes') &&
                    <InnerLayoutStepContent type={'you'}>
                        <RecoveryPeriodYou/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse
                    && (props.longTermReturnsSpouse === 'Yes')
                    &&
                    <InnerLayoutStepContent type={'spouse'}>
                       <RecoveryPeriodSpouse/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

            <Navigation
                isCompleted={true}
                next={retirementCalcLinks.guaranteedFee}
            />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        longTermReturnsYou: state.investorProfile.longTermReturnsYou,
        longTermReturnsSpouse: state.investorProfile.longTermReturnsSpouse,
        shortTermLossesSpouse: state.investorProfile.shortTermLossesSpouse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        secondStepCompleted: (val) => dispatch(secondStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPeriod);
import React from 'react';
import RecoveryPeriodComp from "./RecoveryPeriodComp/RecoveryPeriodComp";
import {
    setChangeRecoveryPeriodSpouse
} from "../../../../store/actions/investorProfileActions";
import { connect } from "react-redux";

const RecoveryPeriodSpouse = ( props ) => {
    return (
        <RecoveryPeriodComp
            onChange={props.onChangeRecoveryPeriodSpouse}
            currentVal={props.recoveryPeriodSpouse}
        />
    )
};

function mapStateToProps(state) {
    return {
        recoveryPeriodSpouse: state.investorProfile.recoveryPeriodSpouse
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onChangeRecoveryPeriodSpouse : val => dispatch(setChangeRecoveryPeriodSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPeriodSpouse);
import React, { useState } from 'react';
import Slider from "react-rangeslider";
import 'react-rangeslider/lib/index.css'
import classes from './RecoveryPeriodComp.module.scss'
import './RecoveryPeriodComp.scss'

const RecoveryPeriodComp = ( props ) => {
    const [ value, setValue ] = useState( props.currentVal || 0);
    const [ handleLabel, setHandleLabel ] = useState( returnRange(value));
    const horizontalLabels = {
        1: `Up to 1 years`,
        3: `1-3 years`,
        5: `3-5 years`
    };

    function returnRange(val) {
        switch (val) {
            case 1:
                return '0-1'
            case 3:
                return '1-3'
            default:
                return '3-5'
        }
    }

    const onChangeRangeHandler = (val) => {
        const range = returnRange(val)
        setHandleLabel(range)

        props.onChange(val)
        setValue(val)
    };

    const format = value => {
        returnRange(value)
    };

    return (
        <div className={classes.SliderWrap}>
            <Slider
                min={1}
                max={5}
                step={2}
                value={value}
                handleLabel={handleLabel}
                format={format}
                tooltip={false}
                labels={horizontalLabels}
                onChange={val => onChangeRangeHandler(val)}
            />
        </div>
    )
};

export default RecoveryPeriodComp;
import React from 'react';
import RecoveryPeriodComp from "./RecoveryPeriodComp/RecoveryPeriodComp";
import { connect } from "react-redux";
import { onChangeRecoveryPeriodYou } from "../../../../store/actions/investorProfileActions";

const RecoveryPeriodYou = ( props ) => {

    return (
        <RecoveryPeriodComp
            onChange={props.onChangeRecoveryPeriodYou}
            currentVal={props.recoveryPeriodYou}
        />
    )
};

function mapStateToProps(state) {
    return {
        recoveryPeriodYou: state.investorProfile.recoveryPeriodYou
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onChangeRecoveryPeriodYou : val => dispatch(onChangeRecoveryPeriodYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RecoveryPeriodYou)